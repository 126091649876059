.Button {
  background: transparent;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 9px;
  width: 132px;
  color: #dfdfdf;
  font-size: 1em;
  position: absolute;
  right: 0;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: color 200ms ease-in-out, border-color 200ms ease-in-out, background-color 200ms ease-in-out;
}

.Button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, .1);
}
