.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.page-enter-done {
  opacity: 1;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.background-image {
  opacity: 1;
  will-change: opacity;
}

.background-image-enter {
  opacity: 0;
}

.background-image-enter-active {
  opacity: 1;
  transition: opacity 1500ms;
}

.background-image-exit {
  opacity: 1;
}

.background-image-exit-active {
  opacity: 0;
  transition: opacity 1500ms;
}

.darkness {
  opacity: 1;
}
