.Container {
  position: relative;
  height: 100vh;
}

.Content_Container {
  position: relative;
  padding-top: 66px;
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
  box-sizing: border-box;
}

.Content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Content h1 {
  color: #dfdfdf;
  font-weight: 500;
  text-align: center;
}

.Links_Tray {
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
}

.Links_Tray a {
  margin: 0 16px;
  display: inline-block;
  height: 32px;
  width: 32px;
}

.Social_Logo {
  font-size: 32px;
  transition: fill .2s ease-in-out 0s, transform .2s ease-in-out 0s, filter .2s ease-in-out 0s;
  transform: scale(.99);
  height: 32px;
  width: 32px;
  fill: #ccc;
  will-change: transform, fill, filter;
}

.Social_Logo:hover {
  fill: #fff;
  transform: scale(1.125);
  transform-origin: center;
  -webkit-filter: 
    drop-shadow( 0 0 8px #fff6)
    drop-shadow( 2px 2px 4px #fff2)
    drop-shadow( -2px 2px 4px #fff2)
    drop-shadow( 2px -2px 4px #fff2)
    drop-shadow( -2px -2px 4px #fff2);
  filter: 
    drop-shadow( 0 0 8px #fff6)
    drop-shadow( 2px 2px 4px #fff2)
    drop-shadow( -2px 2px 4px #fff2)
    drop-shadow( 2px -2px 4px #fff2)
    drop-shadow( -2px -2px 4px #fff2);
}
