.Overlay {
  position: absolute;
  top: 67px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .5;
  background: #000;
  z-index: -1;
  transition: opacity .2s ease-out;
}