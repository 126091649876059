.Container {
  position: relative;
  height: 100vh;
}

.Content_Container {
  padding-top: 66px;
  margin-left: 20px;
  margin-right: 20px;
}

.Tagline_Container {
  position: relative;
  margin-left: 18%;
  margin-top: calc(18% * 0.618);
  width: fit-content;
}

.Tagline {
  color: #dfdfdf;
  font-weight: 500;
  font-style: italic;
  text-align: right;
}
