.Container {
  position: relative;
  height: 100vh;
}

.Content_Container {
  padding-top: 66px;
  height: calc(100% - 66px);
  margin-left: 66px;
  margin-right: 66px;
}

.Scrollable_Container {
  height: 100%;
  width: 100%;
  word-wrap: normal;
  overflow-y: scroll;
  opacity: 1;
  transition: opacity 5s ease-out;
}

.CV_Point {
  padding: 18px;
}

.CV_Point:first-of-type {
  padding-top: 36px;
}

.CV_Header h3 {
  color: #d3d3d3;
  margin-bottom: 4px;
  margin-top: 0;
  font-weight: 500
}

.CV_Location {
  font-style: italic;
}

.CV_Dates h5 {
  color: #888;
  font-weight: 800;
  margin: 0;
}

.CV_Description {
  margin: 6px 42px 0 2px;
}

.CV_Description li {
  color: #aaa;
  margin-bottom: 6px;
}
