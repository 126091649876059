.God {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
}
