.Header {
  background-color: rgba(0,0,0, .8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid #6f6f6f;
}

.Header ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: flex-end;
  padding-right: 20px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 10px 0;
}

.Header ul li {
  padding: 14px 20px ;
}

.Header ul a {
  color: #ccc;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .2s ease-in-out 0s;
  padding-bottom: 3px;
}

.Header ul a:hover {
  color: #fff;
}

.Header ul a:hover::after {
  background-color: #fff;
  transition: background-color .2s ease-in-out, width .2s ease-out;
}

.Header a::after {
  content: '';
  width: 0;
  position: absolute;
  height: 1px;
  background-color: #ccc;
  bottom: 0;
  left:0;
  opacity: 1;
  transition: background-color .2s ease-in-out;
}

.Header a.Active_Link {
  position: relative;
  text-shadow: 0 -4px 6px #000a;
}

.Header a.Active_Link::after {
  width: 100%;
}